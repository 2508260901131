import React, { FC, useEffect,useState } from "react";
import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Header from "src/containers/Header";
import Footer from "src/containers/Footer";
import SideDrawer from "src/components/SideDrawer";
import MobileMenu from "src/components/MobileMenu";
import NotificationBar from "src/components/NotificationBar";
// import { CartDrawer } from "src/components/cartDrawer";
import { StyledWrap } from "./styles"
import "src/styles/main.scss";
// import "../styles/fonts.css";
import { isSSR } from "src/utils/ssr";

const Layout: FC = ({ children, siteMetadata, location, pageContext }) => {
  const socialIcons = pageContext?.social && pageContext.social.length > 0 ? pageContext.social[0].options : null;

  
  useEffect(() => {
    if (isSSR) return;

    const country = process.env.GATSBY_CURRENCY_SYMBOL
    const chatWidget = document.getElementById("gorgias-chat-container");

    if ((pageContext?.slug === "contact" || pageContext?.slug === "returns") && country == "£") {
      if (chatWidget != null) {
        chatWidget.style.display = "block";
      }
    } else {
      if (chatWidget != null) {
        chatWidget.style.display = "none";
      }
    }

  }, [pageContext?.slug])
  
  return (
    <>
    <React.Fragment>
      <StyledWrap>
        {pageContext.notificationBar && (
          <NotificationBar
            enabled={pageContext.notificationBar[0].enabled}
            whiteTextDesktop={pageContext.notificationBar[0].whiteTextDesktop}
            yellowTextDesktop={pageContext.notificationBar[0].yellowTextDesktop}
            whiteTextMobile={pageContext.notificationBar[0].whiteTextMobile}
            yellowTextMobile={pageContext.notificationBar[0].yellowTextMobile}
            discountName={pageContext.notificationBar[0].discountName}
            countdownDate={pageContext.notificationBar[0].countdownDate}
          />
        )}
        <Header
          transparent={
            pageContext._type !== "productsShopify" &&
            pageContext._type !== "collection" &&
            pageContext._type !== undefined
          }
          notificationBar={
            pageContext.notificationBar
              ? pageContext.notificationBar[0].enabled
              : false
          }
          data={
            pageContext.mobileMenu
            ? pageContext.mobileMenu[0]
            :false 
          }
          afterTheLineItems={
            pageContext?.menus 
            ? pageContext.menus[0].afterTheLineItems 
            :false 
          }
        />
      </StyledWrap>
      {pageContext.cartInfo && pageContext.modules && (
        <SideDrawer returnMoneyBack={pageContext?.returnMoneyBack[0]?.drawer?.content} cartInfo={pageContext.cartInfo[0]} additionalInformationImage={pageContext.main?.additionalInformationImage} productCases={pageContext?.main?.productCases} caseText={pageContext?.main?.caseText} caseSubheader={pageContext?.main?.caseSubheader} />
      )}
      {pageContext.mobileMenu && (
        <MobileMenu data={pageContext.mobileMenu[0]} />
      )}
      <main>
        {children}
        <Footer socialIcons={socialIcons}/>
      </main>
      <CookieConsent
        enableDeclineButton
        onAccept={() => {
          initializeAndTrack(location);
        }}
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        declineButtonStyle={{
          width: "115px",
          padding: "0 3.3rem",
          backgroundColor: "grey",
        }}
        buttonStyle={{
          width: "115px",
          padding: "0 3.3rem",
          backgroundColor: "rgb(231, 116, 120)",
          height: "5rem",
        }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        We use cookies to ensure you get the best experience on our website.
      </CookieConsent>
    </React.Fragment>
    </>
  );
};

export default Layout;
