import React, { useState, useEffect } from "react";
import Image from "../Common/ImageFluid";
import { SanityImage } from "../../interfaces/sanity";
import { encode, decode } from "shopify-gid";
import { client, useAddItemToCart, useCart } from "src/context/siteContext";
import Ribbon from "src/components/Ribbon";
import { eventData } from 'src/utils/dataLayer';
import {
  ContainerLink,
  TextContainer,
  Heading,
  InfoContainer,
  DescriptionHeading,
  Description,
  PriceHeading,
  StyledRating,
  CartLink,
  StyledImage,
  ImageContainer,
  DescriptionParagraph,
  OkendoReviews,
  NewProductMessage,
  RatingsBox,
  RatingParagraph,
} from "./styles";
import { useTheme } from "styled-components";
import trackKlavyioAddedToCart from "src/utils/mapKlaviyoPayload";
import locales from 'src/locales';


interface Props {
  id: number;
  slug: string;
  title: string;
  okendoReviews: {
    totalReviews: number;
    rating: number;
  }
  image: SanityImage;
  price: string;
  className?: string;
  description?: string;
  ribbon?: string;
  mobileSmall?: boolean;
  addToCart?: boolean;
  mobilePhoto?: SanityImage;
  ribbonSmall?: boolean;
  onClick?: () => void;
  large?: boolean;
  showPrice?: boolean;
  showRating?: boolean;
  buttonBackgroundColors?: object;
  buttonColors?: object;
  productQuantity?: object;
}

const ProductItem = ({
  id,
  slug,
  title,
  okendoReviews,
  image,
  price,
  className,
  description,
  ribbon,
  mobileSmall,
  addToCart = false,
  mobilePhoto,
  ribbonSmall = false,
  large,
  showPrice,
  showRating,
  buttonColors,
  buttonBackgroundColors,
  newProductMessage,
  productQuantity,
  onClick,
}: Props) => {
  const theme = useTheme();
  const { checkout } = useCart();
  const { addItemToCart } = useAddItemToCart();
  const [available, setAvailable] = useState(false);
  const [variants, setVariants] = useState([]);
  const [check, setCheck] = useState(true);
  const [activeVariantId, setActiveVariantId] = useState("" as string);
  const [compareAtPrice, setCompareAtPrice] = useState(
    undefined as string | undefined
  );
  const [adding, setAdding] = useState<boolean>(false);

  console.log("compare", productQuantity)
  useEffect(() => {
    if (check) {
      const shopifyId = encode("Product", id, {
        accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
      });

      client.product.fetch(shopifyId).then((product: any) => {
        const decodedVariants = [] as any;

        if (!product) return;

        product.variants.forEach((variant: any) => {
          decodedVariants.push({
            ...variant,
            cleanId: parseInt((variant.id).id, 0),
          });
        });

        setVariants(decodedVariants);
        setActiveVariantId(decodedVariants[0].id as string);
        setAvailable(decodedVariants[0].available);

        if (decodedVariants[0].compareAtPrice)
          setCompareAtPrice(decodedVariants[0].compareAtPrice);

        setCheck(false);
      });
    }
  }, [check]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (available) {
      setAdding(true);
      // GA4 add to cart event
      const item = {
        name: title,
        id: variants[0]?.sku,
        image: variants[0]?.image?.src,
        product_id: id,
        price: parseFloat(price),
        brand: 'GroovePillows',
        variant: variants[0]?.title,
        quantity: 1,
      };
      dataLayer?.push({
      ...eventData('add_to_cart'),
        ecommerce: {
          currencyCode: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
          add: {
            products: [item],
          },
        }
      });
      trackKlavyioAddedToCart(checkout, item);
      // TriplePixel('AddToCart', { item: variants[0]?.sku, q: 1 });
      const attributes = [];

      // if (mobilePhoto) {
      //   attributes.push({ key: "mobilePhoto", value: mobilePhoto.asset._ref });
      // }

      addItemToCart(activeVariantId, 1, attributes).then(() => {
        setAdding(false);
      });
    }
  };

  let message = locales.productInfo.new_product_message;
  if (id == 6852326654038 || id == 6852243685462) {
      message = locales.productInfo.moreStock;
  }

  return (
    <ContainerLink
      className={`${className} product-container`}
      to={`/products/${slug}`}
      mobileSmall={mobileSmall}
      onClick={onClick}
    >
      <ImageContainer>
        {image && <StyledImage imageRef={mobilePhoto ? mobilePhoto : image} />}
      </ImageContainer>
      {ribbon && <Ribbon small={ribbonSmall}>{ribbon}</Ribbon>}
      <InfoContainer bigPadding={description ? true : false}>
        <TextContainer addToCart={addToCart}>
          {large ? (
            <>
              <DescriptionHeading>{title}</DescriptionHeading>
              {showPrice && productQuantity?.savingPrice > 0 ? (
                <div style={{display: "flex"}}>
                  <PriceHeading className="strikethrough_price">{process.env.GATSBY_CURRENCY_SYMBOL + price}</PriceHeading>
                  <PriceHeading>{process.env.GATSBY_CURRENCY_SYMBOL + productQuantity?.salesPrice}</PriceHeading>
                </div>
                ) :
                <PriceHeading>
                  {process.env.GATSBY_CURRENCY_SYMBOL + price}
                </PriceHeading>
              }
            </>
          ) : (
            <>
              <Heading>{title}</Heading>
              {showPrice && productQuantity?.savingPrice > 0 ? (
                <div style={{display: "flex"}}>
                  <Heading className="strikethrough_price">{process.env.GATSBY_CURRENCY_SYMBOL + price}</Heading>
                  <Heading>{process.env.GATSBY_CURRENCY_SYMBOL + productQuantity?.salesPrice}</Heading>
                </div>
              ) :   <Heading>{process.env.GATSBY_CURRENCY_SYMBOL + price}</Heading>
              }
            </>
          )}
          {description && <Description blocks={description} />}
        </TextContainer>
        {
        newProductMessage
        ? <NewProductMessage>{message}</NewProductMessage>
        : showRating && 
        <RatingsBox id="rating-box">
          <StyledRating fieldName="productItem" number={okendoReviews?.rating} color={theme.colors.yellow} />
          <RatingParagraph>
            <span>{okendoReviews?.totalReviews} Reviews</span>
          </RatingParagraph>
        </RatingsBox>
        }
        {addToCart && (
          <CartLink className="add-to-cart-cta" onClick={handleClick} btnBackground={buttonBackgroundColors?.value} disabled={!available} style={{backgroundColor: buttonBackgroundColors?.value, color: buttonColors?.value}}>
            {adding ? "Adding..." : "Add to cart"}
          </CartLink>
        )}
      </InfoContainer>
    </ContainerLink>
  );
};

export default ProductItem;
