import styled, { css } from 'styled-components';
import Product from '../ProductItem';

interface ListProps {
  firstLarge?: boolean;
  mobileGrid?: boolean;
}
interface ProductProps {
  isCollection?: boolean;
}

export const StyledProduct = styled(Product)<ProductProps>`
  margin-bottom: 2rem;

  img {
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
      /* height: 200px;
      object-fit: contain; */
      height: ${(props) => (props.isCollection ? '200px' : '')};
      object-fit: ${(props) => (props.isCollection ? 'contain' : '')};
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0;
  }
`;

export const List = styled.div<ListProps>`
  ${({ mobileGrid }) =>
    mobileGrid
      ? css`
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          justify-items: stretch;
        `
      : css`
          display: static;
        `};
  @media ${({ theme }) => theme.device.tablet} {
    display: grid;
    grid-gap: 2rem;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
  ${({ firstLarge }) =>
    firstLarge &&
    css`
      @media ${({ theme }) => theme.device.tablet} {
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-flow: unset;
      }

      ${StyledProduct}:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    `}
`;
